footer{
	padding-top: 3.625rem;
	padding-bottom: 3.3125rem;
	background: #F0F3F9;
	.small-text{
	&.link-pc{
		margin-top: 1.5rem;
		margin-bottom: 1.125rem;
		}
		a{
			color: $paragraf!important;
		}
	}
}
.footer-container{
	justify-content: space-between;
}
.copyright{
	width: 66.2031%;
	color: $text;
}
.footer-bottom{
	background: #F0F3F9;
	padding-top: 13.62px;
	padding-bottom: 1.62px;
	border-top: 1px solid #D9DDE8;
	font-size: 0.875rem;
	}
	.cb{
		justify-content: space-between;
	}
.dcm{
	font-size: 0.8125rem;
	align-items: center;
	color: $text!important;
}
.dcm__img{
	margin-left: 0.5625rem;
}
.footer-item-link{
	font-family: FM;
	font-size: 1.125rem;
	margin-bottom: 1.125rem;
	&:last-child{
		margin-bottom: 0;
		}
	a{
		color: $paragraf!important;
	}
}
.footer-phone{
	color: $paragraf!important;
	font-family: FM;
	font-size: 1.125rem;
}
.footer-mail{
	@extend .footer-phone;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	span{
		border-bottom: 1px solid $paragraf;
	}
}
.footer-adress{
	color: $text;
	margin-bottom: 0.375rem;
}
.show-map{
	cursor: pointer;
	font-family: FM;
	margin-bottom: 1.4375rem;
	span{

		border-bottom: 1px solid $paragraf;
	}
}

.footer-social-widget{
	display: grid;
	grid-template-columns: repeat(2,1.875rem);
	grid-column-gap: 0.6094rem;
	a{
	display: block;
	width: 1.85rem;
	}
	img{
		width: 100%;
		}
}

@media (max-width:1200px) {
	.footer-container {
    display: grid!important;
    grid-template-columns: repeat(3,1fr);
	grid-gap: 1rem;
	}
}
@media (max-width:767px) {
	.dcm span{
	display: none;
	}
}
@media (max-width:620px) {
.cb{
	display: none!important;
	}
	.main-footer {
    display: none;
	}
}