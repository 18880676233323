.lk-content{
	width: 100%;
	overflow: hidden;
	padding: 0px 0px 60px;
	color: #373E44;
	h1{
		padding: 5px 0 20px;
		font-size: 32px;
	}
}

.lk-box{
	margin: 20px 0px 0px;
	.lk-box__menu{
		position: relative;
	}
	.lk-box__content{
		padding: 30px 20px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		h3{
			color: rgba(0, 0, 0, 0.8);
			font-size: 16px;
			font-family: FGM;
			text-transform: uppercase;
			margin: 0px 0 25px;
			padding: 0;
		}
		h4{
			color: rgba(0, 0, 0, 0.8);
			font-size: 16px;
			font-family: FGM;
			text-transform: uppercase;
			margin: 0;
			padding: 15px 0;
		}
	}
}

.lk-menu{
	margin: 0;
	padding: 0;
	list-style-type: none;
	li{
		float: left;
		display: block;
		padding: 15px 20px 13px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-right: none;
		border-bottom: transparent;
		&.active{
			background: $accent;
			a{
				color: #fff;
				}
			&:hover{
				a{
					color: #fff;
					}
				}

		}
		a{
			color: #373E44;
			font-size: 18px;
			font-family: FGM;
			line-height: 1.0;
			text-transform: uppercase;
			text-decoration: none;
			transition: all .4s;
		}
		&:last-child{
			border-right: 1px solid rgba(0, 0, 0, 0.1);
		}
		&:hover, &:focus{
			a{
				color: $accent;
			}
		}
	}
	&:after{
		content: '';
		clear: both;
		display: block;
	}
}

@media (max-width: 1300px){
	.lk-content{
		padding: 0px 0px 50px;
		h1{
			padding: 5px 0 20px;
			font-size: 30px;
		}
	}
	.lk-box{
		.lk-box__content{
			padding: 40px 30px;
			h3{
				font-size: 15px;
				margin: -10px 0 30px;
				padding: 0;
			}
			h4{
				font-size: 15px;
				margin: 0;
				padding: 15px 0;
			}
		}
	}
	.lk-menu{
		li{
			// padding: 0px 30px 0px 0px;
			padding: 12px 12px 10px;
			a{
				font-size: 16px;
			}
		}
	}
}

@media (max-width: 1100px){
	.lk-content{
		padding: 0px 0px 45px;
		h1{
			padding: 5px 0 20px;
			font-size: 28px;
		}
	}
	.lk-box{
		.lk-box__content{
			padding: 40px 20px;
			h3{
				font-size: 14px;
				margin: 0px 0 20px;
				padding: 0;
			}
			h4{
				font-size: 14px;
				margin: 0;
				padding: 15px 0;
			}
		}
	}
	.lk-menu{
		li{
			// padding: 0px 25px 0px 0px;
			padding: 8px 10px 8px;
			a{
				font-size: 14px;
			}
		}
	}
}

@media (max-width: 768px){
	.lk-content{
		padding: 0px 0px 40px;
		h1{
			padding: 5px 0 20px;
			font-size: 26px;
		}
	}
	.lk-box{
		.lk-box__content{
			padding: 30px 20px;
			h3{
				font-size: 13px;
				margin: 0px 0 20px;
				padding: 0;
			}
			h4{
				font-size: 13px;
				margin: 0;
				padding: 15px 0;
			}
		}
	}
	.lk-user-info{
		.lk-user-info__heading{
			display: inline-block;
			vertical-align: top;
		}
		.lk-user-info__body{
			display: inline-block;
			vertical-align: top;
		}
	}
	.lk-menu{
		li{
			// padding: 0px 20px 10px 0px;
			a{
				font-size: 13px;
			}
		}
	}
}

@media (max-width: 640px){
	.lk-content{
		padding: 0px 0px 40px;
		h1{
			padding: 5px 0 20px;
			font-size: 24px;
		}
	}
	.lk-box{
		.lk-box__content{
			padding: 30px 15px;
		}
	}
	.lk-menu{
		display: table;
		width: 100%;
		li{
			float: none;
			display: table-cell;
			text-align: center;
		}
	}
}

@media (max-width: 480px){
	.lk-content{
		padding: 0px 0px 30px;
		h1{
			padding: 5px 0 20px;
			font-size: 24px;
		}
	}
	.lk-menu{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		width: 100%;
		margin: 0 0 20px;
		li{
			float: none;
			display: block;
			text-align: left;
			border: 1px solid rgba(0, 0, 0, 0.1);
			border-bottom: transparent;
			&:last-child{
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			}
		}
	}
}