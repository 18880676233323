.fix-mobile-menu{
	position: fixed;
	z-index: 1000;
	top: 0;
	left: -100%;
	background: #fff;
	width: 100%;
	height: 100%;
	transition: all .4s;
	overflow: hidden;
	overflow-y: auto;
	padding-bottom: 1rem;
	&.active{
		left: 0;
	}
}
.fix-container{
width: 320px;
margin: 0 auto;
padding: 0 15px;
.subMenu{
	display: block;
	.listItem{
		margin-bottom: 10px;
		}
	}
a{
display: block;
color: $paragraf!important;
font-family: FM;
font-size: 1.2rem;
margin-bottom: 10px;
&:last-of-type{
	margin-bottom: 0;
	}
}
}
.fix-header{
	width: 100%;
	height: 60px;
	justify-content: space-between;
	margin-bottom: 2rem;
	border-bottom: 1px solid #ccc;
	padding-left: 15px;
}
.fix-close{
	width: 40px;
	background: url('../img/cfb.svg') no-repeat center center;
}

@media (Max-width:420px) {
	.fix-header{
		height: 45px;
	}
}