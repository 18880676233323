.container-lk{
	padding-top: 100px;
	max-width: 1200px;

	}
.lk-user{
	.lk-user__img{
		float: left;
		width: 200px;
		text-align: center;
		line-height: 200px;
		background: rgba(0, 0, 0, 0.1);
		img{
			max-width: 100%;
			max-height: 100%;
			&.nophoto{
				display: inline-block;
				width: 100px;
				height: 86px;
			}
		}
	}
	.lk-user__avatar{
		width: 100%;
		height: 200px;
		text-align: center;
		line-height: 200px;
		background: rgba(0, 0, 0, 0.1);
		img{
			max-width: 100%;
			max-height: 100%;
			&.nophoto{
				display: inline-block;
				width: 100px;
				height: 86px;
			}
		}
	}
	.lk-user__content{
		float: right;
		width: calc(100% - 230px);
		&:after{
			content: '';
			clear: both;
			display: block;
		}
	}
	.lk-user__info{
		float: left;
		width: 400px;
	}
	.lk-user__contact{
		float: right;
		width: 400px;
	}
	.lk-user__name{
		color: #373E44;
		font-size: 18px;
		font-family: FGM;
		line-height: 22px;
		// text-transform: uppercase;
		padding: 14px 0 15px;
	}
	.lk-user__item{
		color: #373E44;
		font-size: 16px;
		font-family: FGM;
		line-height: 1.2;
		padding: 0 0 10px;
		span{
			color: #a7a7a7;
			padding: 0 5px 0 0;
		}
		a{
			color: #1f1f1f;
			text-transform: uppercase;
			&:hover, &:focus, &:active{
				color: #005da4;
			}
		}
	}
	&:after{
		content: '';
		clear: both;
		display: block;
	}
}

@media (max-width: 1300px){
	.lk-user{
		.lk-user__img{
			width: 180px;
			line-height: 180px;
			img{
				&.nophoto{
					width: 90px;
					height: 76px;
				}
			}
		}
		.lk-user__avatar{
			height: 180px;
			line-height: 180px;
			img{
				&.nophoto{
					width: 90px;
					height: 76px;
				}
			}
		}
		.lk-user__content{
			width: calc(100% - 210px);
		}
		.lk-user__info{
			width: 50%;
		}
		.lk-user__contact{
			width: 50%;
		}
		.lk-user__name{
			font-size: 16px;
			line-height: 20px;
			padding: 14px 0 15px;
		}
		.lk-user__item{
			font-size: 14px;
			line-height: 1.2;
			padding: 0 0 10px;
			span{
				padding: 0 5px 0 0;
			}
		}
	}
}

@media (max-width: 1100px){
	.lk-user{
		.lk-user__img{
			width: 160px;
			line-height: 160px;
			img{
				&.nophoto{
					width: 80px;
					height: 66px;
				}
			}
		}
		.lk-user__avatar{
			height: 160px;
			line-height: 160px;
			img{
				&.nophoto{
					width: 80px;
					height: 66px;
				}
			}
		}
		.lk-user__content{
			width: calc(100% - 190px);
		}
		.lk-user__info{
			width: 50%;
		}
		.lk-user__contact{
			width: 50%;
		}
		.lk-user__name{
			font-size: 15px;
			line-height: 18px;
			padding: 14px 0 15px;
		}
		.lk-user__item{
			font-size: 14px;
			line-height: 1.2;
			padding: 0 0 10px;
			span{
				padding: 0 5px 0 0;
			}
		}
	}
}

@media (max-width: 768px){
	.lk-user{
		.lk-user__img{
			width: 200px;
			line-height: 200px;
			img{
				&.nophoto{
					width: 80px;
					height: 66px;
				}
			}
		}
		.lk-user__avatar{
			height: 200px;
			line-height: 200px;
			img{
				&.nophoto{
					width: 80px;
					height: 66px;
				}
			}
		}
		.lk-user__content{
			width: calc(100% - 240px);
		}
		.lk-user__info{
			width: 100%;
		}
		.lk-user__contact{
			width: 100%;
		}
		.lk-user__name{
			padding: 0px 0 10px;
		}
	}
}

@media (max-width: 480px){
	.lk-user{
		.lk-user__img{
			display: block;
			width: 200px;
			width: 100%;
			line-height: 200px;
			img{
				&.nophoto{
					width: 80px;
					height: 66px;
				}
			}
		}
		.lk-user__avatar{
			width: 100%;
			height: 200px;
			line-height: 200px;
			img{
				&.nophoto{
					width: 80px;
					height: 66px;
				}
			}
		}
		.lk-user__content{
			display: block;
			width: 100%;
			padding: 30px 0px 0px;
		}
		.lk-user__info{
			width: 100%;
		}
		.lk-user__contact{
			width: 100%;
		}
		.lk-user__name{
			padding: 0px 0 10px;
		}
	}
}