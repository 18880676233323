.search-list{
	display: grid;
	grid-template-columns: repeat(5,1fr );
	grid-gap: 20px;
	.product-item-glide{
		border: 1px solid #dcdcdc;
	}
}
.result-search-container{
	margin-top: 2rem;
}
.search-result-content{
	padding-bottom: 5rem;
	.pagination:not(ul){
		padding-top: 1rem;
		margin-top: 1rem;
	}
}


@media (max-width:1400px) {
	.search-list{
	grid-template-columns: repeat(4, 1fr);
	}
}
@media (max-width:1100px) {
	.search-list{
	grid-template-columns: repeat(3, 1fr);
	}
}
@media (max-width:767px) {
	.search-list{
	grid-template-columns: repeat(2, 1fr);
	}
}
@media (max-width:480px) {
	.search-list{
	grid-template-columns: repeat(1, 1fr);
	.prod__img img{
	width: unset;
	}
	}
}